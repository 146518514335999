@charset "UTF-8";

// color
$colorBrown: #603813;
$colorSidenav: #42210B;
$colorYellow: #ffcc00;
$colorOrange: #ff8123;
$colorFoodstuff: #7ec044;
$colorProducer: #1d9dcd;

//コンビモード
$color-combi: #ea6f00;
//ホットエアモード
$color-hotair: #ec514b;
//スチームモード
$color-steam: #46b3e4;
//下ごしらえ
$color-preparation: #ffc300;

// icon
$icon-contact: "\a900";
$icon-crown: "\a901";
$icon-everio: "\a902";
$icon-faq: "\a903";
$icon-flag: "\a904";
$icon-hospital: "\a905"; 
$icon-search: "\a906";
$icon-beginner: "\a907";
$icon-catalog: "\a908";
$icon-change: "\a909";
$icon-config: "\a910";
$icon-logout: "\a911";
$icon-home: "\a912";
$icon-hospitalfood: "\a913";
$icon-labo: "\a914";
$icon-tech: "\a915";
$icon-testkitchen: "\a916";
$icon-case: "\a917";
$icon-logo: "\a918";
$icon-recipesearch: "\a919";
$icon-login: "\a920";
$icon-plate: "\a921";
$icon-sclass: "\a922";
$icon-download: "\a923";
$icon-print: "\a924";
$icon-recipe: "\a925";
$icon-check: "\a926";
$icon-time: "\a927";
$icon-memo: "\a928";
$icon-all: "\a929";
$icon-newwin: "\a930";
$icon-aclass: "\a931";
$icon-cockhat: "\a932";
$icon-nobuzzer: "\a933";
$icon-continue: "\a934";
$icon-return: "\a935";
$icon-hospital01: "\e904";
$icon-hospital02: "\e903";
$icon-hospital03: "\e902";
$icon-hospital04: "\e901";
$icon-hospital05: "\e900";
$icon-hospital06: "\e906";
$icon-hospital07: "\e905";
$icon-mini: "\e907";
$icon-cookeverio: "\e908";

// X mark (ex.<span class="close"></span>)
@mixin mark-x {
  // ×を囲む正方形を追加　※posiziton:absolute or relative入れてね
  &:before,
  &:after {
    display: inline-block;
    content: "";
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin: auto;
    // 線のサイズを追加　※長さは枠幅×√2くらい
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

// Link-hover1：四角がポップアップする 
@mixin hover-pop {
  // Add posiziton:absolute or relative
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgba(200, 155, 5, .3);
    border-radius: 5px;
    opacity: 0;
    transform: scale(.9, .9);
    transition: .15s;
  }
  &:hover {
    &:before {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
}
// Link-hover2：暗めの黄色背景になる
@mixin hover-dark {
  transition: .15s;
  &:hover {
    background-color: darken($colorYellow, 5%);
    box-shadow: 0 0 0 #b79a21;
  }
}

// button ※background-color は個別に設定
@mixin btn {
  box-sizing: border-box;
  border: 0;
  overflow: hidden;
  cursor: pointer;
}


// for over w1280 ---------------------

// for less w1280
@mixin laptop {
  @media (max-width: 1279px) {
    @content;
  }
}
// for sp
@mixin sp {
  @media (max-width: 425px) {
    @content;
  }
}
// to exclude sp
@mixin notSp {
  @media (min-width: 425px) {
    @content;
  }
}

// icomoon
@mixin icon2 {
	font-family: "icomoon" !important;
	font-weight: normal;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// font-size
@mixin fz($size, $base: 16) {
	font-size: $size + px;
	font-size: ($size / $base) + rem;
}

// #clearfix
@mixin clearfix {
	&:after {
		font-size: 0;
		display: block;
		visibility: hidden;
		clear: both;
		height: 0;
		content: ".";
	}
}
