@charset "UTF-8";
.topslider {
  position: relative;
  overflow: hidden;
  padding: 20px 0 40px 0;
  height: 480px;
  box-sizing: border-box;
  background: url("/container/common/images/bg-main-content.png");
}

@media (max-width: 425px) {
  .topslider {
    height: auto;
    padding: 0 0 40px 0;
  }
}

.topslider--wrap {
  position: relative;
  opacity: 0;
}

.topslider--wrap.flickity-enabled {
  opacity: 1;
}

.topslider--link {
  margin-right: 20px;
  filter: brightness(85%);
  transition: .15s;
}

@media (max-width: 425px) {
  .topslider--link {
    width: 100%;
  }
}

.topslider--link-sp {
  display: flex;
  justify-content: center;
}

.topslider--link.is-selected {
  filter: brightness(100%);
}

.topslider--link:hover {
  filter: brightness(104%);
}

.topslider--link_sp:hover {
  filter: brightness(104%);
}

.topslider--bnr {
  max-width: 750px;
}

@media (max-width: 425px) {
  .topslider--bnr {
    width: 100%;
    height: auto;
  }
}

.topslider--nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 780px;
  height: 100%;
  pointer-events: none;
}

@media (max-width: 425px) {
  .topslider--nav {
    width: 100%;
  }
}

.topslider--arw {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #603813;
  pointer-events: auto;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  transition: .3s;
}

.topslider--arw:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 10px;
  height: 10px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
}

.topslider--arw-l {
  left: -20px;
}

.topslider--arw-l:before {
  left: 20px;
  transform: rotate(-135deg);
}

.topslider--arw-l:hover {
  left: -30px;
}

.topslider--arw-r {
  right: -20px;
}

.topslider--arw-r:before {
  right: 20px;
  transform: rotate(45deg);
}

.topslider--arw-r:hover {
  right: -30px;
}

@media (max-width: 425px) {
  .topslider--arw {
    display: none;
  }
}

.topslider .flickity-page-dots {
  position: absolute;
  right: 0;
  left: 0;
  bottom: -38px;
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
}

.topslider .flickity-page-dots .dot {
  display: block;
  border: 2px solid #603813;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0 10px 0 0;
  transition: .15s;
}

.topslider .flickity-page-dots .dot:hover, .topslider .flickity-page-dots .dot.is-selected {
  background-color: #facd39;
}

@media (max-width: 425px) {
  .topslider .flickity-page-dots .dot {
    width: 10px;
    height: 10px;
  }
}

.topnav {
  width: 100%;
  padding: 40px 0;
}

@media (max-width: 425px) {
  .topnav {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.topnav--bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin: 0 0 30px 0;
}

@media (max-width: 425px) {
  .topnav--bar {
    display: block;
    height: auto;
    margin-bottom: 0;
  }
}

.topnav--linkbtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  height: 72px;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 0.875rem;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  transition: .3s;
}

.topnav--linkbtn:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: calc(50% - 15px);
}

.topnav--linkbtn:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto 0;
  width: 5px;
  height: 5px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
}

.topnav--linkbtn:first-child {
  background-color: #7ac943;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 3px solid #4b9d00;
  border-bottom: 3px solid #4b9d00;
  padding-left: 10px;
}

.topnav--linkbtn:first-child:before {
  font-size: 30px;
  font-size: 1.875rem;
  content: "꤇";
  left: 20px;
}

.topnav--linkbtn:first-child:hover {
  background-color: #4b9d00;
}

.topnav--linkbtn:nth-child(2) {
  background-color: #ff8123;
  border-right: 3px solid #c15825;
  border-bottom: 3px solid #c15825;
}

.topnav--linkbtn:nth-child(2):before {
  font-size: 28px;
  font-size: 1.75rem;
  content: "꤆";
  left: 20px;
}

.topnav--linkbtn:nth-child(2):hover {
  background-color: #c15825;
}

.topnav--linkbtn:last-child {
  background-color: #ff7bac;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 3px solid #d15091;
  border-bottom: 3px solid #d15091;
}

.topnav--linkbtn:last-child:before {
  font-size: 28px;
  font-size: 1.75rem;
  content: "꤅";
  left: 20px;
}

.topnav--linkbtn:last-child:hover {
  background-color: #d15091;
}

@media (max-width: 425px) {
  .topnav--linkbtn {
    width: 100%;
    height: 50px;
  }
  .topnav--linkbtn:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .topnav--linkbtn:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.topnav--linkbtn_st {
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: -1px;
  font-weight: 400;
}

.topnav--menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin: 0 0 30px 0;
}

@media (max-width: 425px) {
  .topnav--menu {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

.topnav--linkicon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: calc(100% / 6);
  height: 75px;
  font-size: 14px;
  font-size: 0.875rem;
  text-decoration: none;
  text-align: center;
  color: #603813;
  font-weight: bold;
  border-right: 1px solid #d1c0b0;
  padding-bottom: 5px;
  box-sizing: border-box;
}

.topnav--linkicon:last-child {
  border-right: none;
}

.topnav--linkicon:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: calc(100% / 6);
}

.topnav--linkicon:after {
  position: absolute;
  top: 0;
  left: 5px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: 100%;
  border-radius: 5px;
  transform: scale(0.9, 0.9);
  transition: .15s;
}

.topnav--linkicon:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

@media (max-width: 425px) {
  .topnav--linkicon {
    width: calc(100% / 3);
    height: 60px;
    font-size: 12px;
    font-size: 0.75rem;
    margin-bottom: 20px;
  }
  .topnav--linkicon:nth-child(3) {
    border-right: none;
  }
}

.topnav--linkicon-beginner:before {
  content: "꤇";
  font-size: 32px;
  font-size: 2rem;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  .topnav--linkicon-beginner:before {
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: 5px;
  }
}

.topnav--linkicon-tech:before {
  content: "ꤕ";
  font-size: 33px;
  font-size: 2.0625rem;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  .topnav--linkicon-tech:before {
    font-size: 31px;
    font-size: 1.9375rem;
    margin-bottom: 5px;
  }
}

.topnav--linkicon-case:before {
  content: "ꤗ";
  font-size: 33px;
  font-size: 2.0625rem;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  .topnav--linkicon-case:before {
    font-size: 31px;
    font-size: 1.9375rem;
    margin-bottom: 5px;
  }
}

.topnav--linkicon-labo:before {
  content: "ꤔ";
  font-size: 34px;
  font-size: 2.125rem;
  margin-bottom: 12px;
}

@media (max-width: 425px) {
  .topnav--linkicon-labo:before {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 5px;
  }
}

.topnav--linkicon-hospitalfood:before {
  content: "ꤓ";
  font-size: 39px;
  font-size: 2.4375rem;
  margin-bottom: 10px;
}

@media (max-width: 425px) {
  .topnav--linkicon-hospitalfood:before {
    font-size: 37px;
    font-size: 2.3125rem;
    margin-bottom: 5px;
  }
}

.topnav--linkicon-testkitchen:before {
  content: "ꤖ";
  font-size: 28px;
  font-size: 1.75rem;
  margin-bottom: 15px;
}

@media (max-width: 425px) {
  .topnav--linkicon-testkitchen:before {
    font-size: 26px;
    font-size: 1.625rem;
    margin-bottom: 5px;
  }
}

.top_content {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  padding: 0 0 40px 0;
}

@media (max-width: 425px) {
  .top_content {
    display: block;
  }
}

.top_content--pages {
  margin: -42px 0 0 -22px;
  width: 690px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .top_content--pages {
    margin: 0;
    width: 100%;
    display: block;
    padding: 20px 0;
  }
}

.top_archive {
  margin: 22px 0 0 22px;
  width: calc(50% - 22px);
  transition: .3s;
}

.top_archive.hidden {
  opacity: .8;
}

@media (max-width: 425px) {
  .top_archive {
    margin: 0 auto;
    padding: 10px 0;
    width: 320px;
  }
}

.top_archive--main_txt {
  position: relative;
}

.top_archive--main_bg {
  position: absolute;
  top: -50px;
  left: -230px;
  background-color: rgba(255, 255, 255, 0.4);
  display: block;
  width: 200px;
  height: 200%;
  transform: rotate(15deg);
}

.top_archive--main_link {
  position: relative;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
  padding: 10px 50px 8px 10px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  box-sizing: border-box;
  height: 150px;
  transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);
  background-size: cover;
  background-repeat: no-repeat;
}

.top_archive--main_link:before {
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 252, 252, 0) 0%, #6b4426 100%);
  content: "";
  width: 100%;
  height: 80px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00fffcfc', endColorstr='#6b4426', GradientType=0);
}

.top_archive--main_link:after {
  content: "";
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 10px;
  height: 10px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
}

.top_archive--main_link:hover {
  transform: scale(0.95, 0.95);
  z-index: 1;
}

.top_archive--main_link-hospital {
  background-image: url("/images/top-content-pic-0.jpg");
}

.top_archive--main_link-coock {
  background-image: url("/images/top-content-pic-1.jpg");
}

.top_archive--main_link-recipe {
  background-image: url("/images/top-content-pic-2.jpg");
}

.top_archive--main_link-every {
  background-image: url("/images/top-content-pic-3.jpg");
}

.top_content_side {
  position: relative;
  width: 230px;
  margin: -22px 0 0 0;
}

@media (max-width: 425px) {
  .top_content_side {
    width: 100%;
    margin-top: 0;
    margin-bottom: auto;
    padding: 20px 0 0 0;
    background-image: url("/container/common/images/bg-main-content.png");
  }
  .top_content_side:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 80px 80px 0 0;
    border-color: #603813 transparent transparent transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.top_content_side--pickup {
  display: none;
  position: absolute;
  top: 20px;
  left: 0;
  transform: rotate(-45deg);
}

@media (max-width: 425px) {
  .top_content_side--pickup {
    display: block;
  }
}

.top_content_side--bnr {
  position: relative;
  line-height: 0;
  display: block;
  transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;
  margin-bottom: 10px;
}

.top_content_side--bnr:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.top_content_side--bnr:hover img {
  transform: scale(0.95, 0.95);
}

.top_content_side--bnr:hover:after {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) inset;
}

.top_content_side--bnr.show {
  left: 0;
  opacity: 1;
}

@media (max-width: 425px) {
  .top_content_side--bnr {
    display: block;
    width: 230px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
}

.top_content_side--bnr_img {
  transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 100%;
}

.top_big_btn {
  margin: 22px 0 0 0;
  position: relative;
  top: 0;
  text-align: center;
  background-color: #ffcc00;
  width: 100%;
  transition: .15s;
  color: #603813;
  text-decoration: none;
  height: 66px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.top_big_btn--img {
  width: 180px;
}

.top_big_btn:hover {
  background-color: #e6b800;
  box-shadow: 0 0 0 #b79a21;
}

@media (max-width: 425px) {
  .top_big_btn {
    display: none;
    width: 230px;
    margin: 0 auto 20px auto;
  }
}

.top_archive_list {
  background-color: #fff;
  border: 1px solid #ccc;
  min-height: 112px;
}

.top_archive_list--item {
  padding: 0 20px;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
}

.top_archive_list--item:last-child .top_archive_list--link {
  border-bottom: 0;
}

.top_archive_list--link {
  padding: 8px 0 12px 20px;
  position: relative;
  text-decoration: none;
  display: block;
  border-bottom: 1px dotted #ddd5cd;
  color: #603813;
}

.top_archive_list--link:before {
  position: absolute;
  top: calc(50% - 3px);
  left: 0;
  content: "";
  display: block;
  transform: rotate(-90deg);
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #603813 transparent transparent transparent;
  transition: .15s;
}

.top_archive_list--link:after {
  position: absolute;
  top: 5px;
  left: -14px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% + 28px);
  height: calc(100% - 10px);
  border-radius: 5px;
  transform: scale(0.9, 0.9);
  transition: .15s;
}

.top_archive_list--link:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

.top_archive_list--date {
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0 0 5px 0;
  display: inline-block;
}

.top_archive_list--icon {
  margin: 0 0 0 5px;
  position: relative;
  top: 2px;
  display: inline-block;
}

.top_archive_list--ttl {
  font-size: 14px;
  font-size: 0.875rem;
}

.new_release {
  position: relative;
  padding: 40px 0;
  background: #f0ebe0;
}

.new_release--zigzag {
  position: relative;
  background: #f0ebe0;
  height: 30px;
}

.new_release--zigzag:before {
  content: '';
  position: absolute;
  right: 0;
  left: 0;
  top: -30px;
  z-index: 5;
  display: block;
  height: 30px;
  background-size: 30px 100%;
  background-image: linear-gradient(135deg, #f0ebe0 25%, transparent 25%), linear-gradient(225deg, #f0ebe0 25%, transparent 25%);
  background-position: 0 0;
  transform: rotate(180deg);
}

.new_release--inner {
  position: relative;
  width: 960px;
  margin: 0 auto;
}

.new_release--inner:after {
  content: "";
  width: 200px;
  height: 130px;
  background-image: url("/container/common/images/charactor03.png");
  background-repeat: no-repeat;
  position: absolute;
  bottom: -35px;
  right: 0;
}

@media (max-width: 425px) {
  .new_release--inner {
    width: 100%;
    padding-bottom: 80px;
  }
  .new_release--inner:after {
    right: 17%;
  }
}

.new_release--ttl {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 40px 0;
  color: #603813;
  text-align: center;
}

.new_release--more_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c8bdb2;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-size: 0.875rem;
  width: 260px;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  transition: .15s;
}

.new_release--more_btn:hover {
  background: #a89785;
}

.new_release--side {
  position: absolute;
  top: 70px;
  right: 0;
  width: 230px;
}

@media (max-width: 425px) {
  .new_release--side {
    position: relative;
    margin: 30px auto;
    top: auto;
    right: auto;
  }
}

.new_release--btn {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  border-radius: 5px;
  background-color: #916843;
  box-shadow: 0 2px 0 #775b4d;
  transition: .15s;
}

.new_release--btn:before {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  font-size: 1.875rem;
  margin: 0 10px 0 -10px;
  content: "꤉";
  display: inline-block;
}

.new_release--btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0 0 0 #775b4d inset;
  transition: .15s;
}

.new_release--btn:hover {
  top: 2px;
  background-color: #805b3b;
  box-shadow: 0 0 0 #775b4d;
}

.new_release--btn:hover:after {
  box-shadow: 0 2px 0 #775b4d inset;
}

.new_release_list {
  list-style: none;
  width: 690px;
  margin: 0 0 30px 0;
}

@media (max-width: 425px) {
  .new_release_list {
    width: 90%;
    margin: 0 auto 30px auto;
  }
}

.new_release_list--item {
  border-bottom: 2px dashed #fff;
  padding: 15px 0 10px 0;
}

.new_release_list--link {
  position: relative;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  color: #000;
}

.new_release_list--link:after {
  position: absolute;
  top: -8px;
  left: -8px;
  opacity: 0;
  background-color: #c89b05;
  content: "";
  display: block;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border-radius: 5px;
  transition: .15s;
  transform: scale(0.9, 0.9);
}

.new_release_list--link:hover:after {
  opacity: .3;
  transform: scale(1, 1);
}

@media (max-width: 425px) {
  .new_release_list--link {
    display: block;
  }
  .new_release_list--link .release {
    display: block;
    margin: 0 0 5px 0;
  }
}

.new_release_list--description {
  width: 580px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.5;
}

@media (max-width: 425px) {
  .new_release_list--description {
    width: auto;
  }
}
