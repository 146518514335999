@charset "UTF-8";

@import "../mixin";

.topslider {
  position: relative;
  overflow: hidden;
  padding: 20px 0 40px 0;
  height: 480px;
  box-sizing: border-box;
  background: url("/container/common/images/bg-main-content.png");
  @include sp {
    height: auto;
    padding: 0 0 40px 0;
  }
  &--wrap {
    position: relative;
    opacity: 0;
    //js読み込んでから表示
    &.flickity-enabled {
      opacity: 1;
    }
  }
  &--link {
    //display: block;
    margin-right: 20px;
    //width: 750px;
    filter: brightness(85%);
    transition: .15s;
    @include sp {
      width: 100%;
    }
    // 生産地から：左右でリンクが分かれる場合
    &-sp {
      display: flex;
      justify-content: center;
    }
    &.is-selected {
      filter: brightness(100%);
    }
    &:hover {
      filter: brightness(104%);
    }
  }
  &--link_sp {
    &:hover {
      filter: brightness(104%);
    }
  }
  &--bnr {
    max-width: 750px;
    @include sp {
      width: 100%;
      height: auto;
    }
  }
  &--nav {
    position: absolute;
    top:0;
    left:0;
    right:0;
    margin: 0 auto;
    width: 780px;
    height: 100%;
    pointer-events: none;
    @include sp {
      width: 100%;
    }
  }
  &--arw {
    position: absolute;
    top:0;
    bottom:0;
    margin: auto 0;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color:$colorBrown;
    pointer-events: auto;
    border:none;
    cursor: pointer;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    transition: .3s;
    &:before {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      margin: auto 0;
      width: 10px;
      height: 10px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
    }
    &-l {
      left:-20px;
      &:before {
        left:20px;
        transform: rotate(-135deg);
      }
      &:hover {
        left:-30px;
      }
    }
    &-r {
      right:-20px;
      &:before {
        right:20px;
        transform: rotate(45deg);
      }
      &:hover {
        right:-30px;
      }
    }
    @include sp {
      display: none;
    }
  }
  .flickity-page-dots {
    position: absolute;
    right:0;
    left:0;
    bottom:-38px;
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
  .flickity-page-dots .dot {
    display: block;
    border:2px solid $colorBrown;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-sizing: border-box;
    margin: 0 10px 0 0;
    transition: .15s;
    &:hover,
    &.is-selected {
      background-color: #facd39;
    }
    @include sp {
      width: 10px;
      height: 10px;
    }
  }
}

.topnav {
  width: 100%;
  padding: 40px 0;
  @include sp {
    padding-top: 0;
    padding-bottom: 0;
  }
  &--bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin: 0 0 30px 0;
    @include sp {
      display: block;
      height: auto;
      margin-bottom: 0;
    }
  }
  &--linkbtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 72px;
    box-sizing: border-box;
    @include fz(14);
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    transition: .3s;
    &:before {
      @include icon2;
      position: absolute;
      top: calc(50% - 15px);
    }
    &:after {
      content: "";
      position: absolute;
      top:0;
      bottom:0;
      right: 15px;
      margin: auto 0;
      width: 5px;
      height: 5px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(45deg);
    }
    &:first-child {
      background-color: #7ac943;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-right: 3px solid #4b9d00;
      border-bottom: 3px solid #4b9d00;
      padding-left:10px;
      &:before {
        @include fz(30);
        content: $icon-beginner;
        left: 20px;
      }
      &:hover {
        background-color: #4b9d00;
      }
    }
    &:nth-child(2) {
      background-color: #ff8123;
      border-right: 3px solid #c15825;
      border-bottom: 3px solid #c15825;
      &:before {
        @include fz(28);
        content: $icon-search;
        left: 20px;
      }
      &:hover {
        background-color: #c15825;
      }
    }
    &:last-child {
      background-color: #ff7bac;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-right: 3px solid #d15091;
      border-bottom: 3px solid #d15091;
      &:before {
        @include fz(28);
        content: $icon-hospital;
        left:20px;
      }
      &:hover {
        background-color: #d15091;
      }
    }
    @include sp {
      width: 100%;
      height: 50px;
      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &--linkbtn_st {
    @include fz(18);
    letter-spacing: -1px;
    font-weight: 400;
  }
  &--menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    margin: 0 0 30px 0;
    @include sp {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
  &--linkicon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: calc(100% / 6);
    height: 75px;
    @include fz(14);
    text-decoration: none;
    text-align: center;
    color: $colorBrown;
    font-weight: bold;
    border-right: 1px solid #d1c0b0;
    padding-bottom:5px;
    box-sizing: border-box;
    &:last-child {
      border-right: none;
    }
    &:before {
      @include icon2;
      width: calc(100% / 6);
    }
    &:after {
      position: absolute;
      top: 0;
      left: 5px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% - 10px);
      height: 100%;
      border-radius: 5px;
      transform: scale(.9, .9);
      transition: .15s;
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
    @include sp {
      width: calc(100% / 3);
      height: 60px;
      @include fz(12);
      margin-bottom: 20px;
      &:nth-child(3) {
        border-right: none;
      }
    }
    &-beginner {
      &:before {
        content: $icon-beginner;
        @include fz(32);
        margin-bottom: 15px;
      }
      @include sp {
        &:before {
          @include fz(30);
          margin-bottom: 5px;
        }
      } 
    }
    &-tech {
      &:before {
        content: $icon-tech;
        @include fz(33);
        margin-bottom: 15px;
      }
      @include sp {
        &:before {
          @include fz(31);
          margin-bottom: 5px;
        }
      } 
    }
    &-case {
      &:before {
        content: $icon-case;
        @include fz(33);
        margin-bottom: 15px;
      }
      @include sp {
        &:before {
          @include fz(31);
          margin-bottom: 5px;
        }
      } 
    }
    &-labo {
      &:before {
        content: $icon-labo;
        @include fz(34);
        margin-bottom: 12px;
      }
      @include sp {
        &:before {
          @include fz(32);
          margin-bottom: 5px;
        }
      } 
    }
    &-hospitalfood {
      &:before {
        content: $icon-hospitalfood;
        @include fz(39);
        margin-bottom: 10px;
      }
      @include sp {
        &:before {
          @include fz(37);
          margin-bottom: 5px;
        }
      } 
    }
    &-testkitchen {
      &:before {
        content: $icon-testkitchen;
        @include fz(28);
        margin-bottom: 15px;
      }
      @include sp {
        &:before {
          @include fz(26);
          margin-bottom: 5px;
        }
      } 
    }
  }
}

.top_content {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  padding: 0 0 40px 0;
  @include sp {
    display: block;
  }
  &--pages {
    margin: -42px 0 0 -22px;
    width: 690px;
    display: flex;
    flex-wrap: wrap;
    @include sp {
      margin: 0;
      width: 100%;
      display: block;
      padding: 20px 0;
    }
  }
}

.top_archive {
  margin: 22px 0 0 22px;
  width: calc(50% - 22px);
  transition: .3s;
  &.hidden {
    opacity: .8;
  }
  @include sp {
    margin: 0 auto;
    padding: 10px 0;
    width: 320px;
  }
  &--main_txt {
    position: relative;
  }
  &--main_bg {
    position: absolute;
    top: -50px;
    left: -230px;
    background-color: rgba(255, 255, 255, .4);
    display: block;
    width: 200px;
    height: 200%;
    transform: rotate(15deg);
  }
  &--main_link {
    position: relative;
    @include fz(14);
    line-height: 1.5;
    text-decoration: none;
    color: #fff;
    padding: 10px 50px 8px 10px;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    box-sizing: border-box;
    height: 150px;
    transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, .3);
    background-size: cover;
    background-repeat: no-repeat;
    &:before {
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(255, 252, 252, 0) 0%, rgba(107, 68, 38, 1) 100%);
      content: "";
      width: 100%;
      height: 80px;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00fffcfc', endColorstr='#6b4426', GradientType=0);
    }
    &:after {
      content: "";
      position: absolute;
      bottom:20px;
      right:20px;
      width: 10px;
      height: 10px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      transform: rotate(45deg);
    }
    &:hover {
      transform: scale(0.95, 0.95);
      z-index: 1;
    }
    &-hospital {
      background-image: url("/images/top-content-pic-0.jpg");
    }
    &-coock {
      background-image: url("/images/top-content-pic-1.jpg");
    }
    &-recipe {
      background-image: url("/images/top-content-pic-2.jpg");
    }
    &-every {
      background-image: url("/images/top-content-pic-3.jpg");
    }
  }
}

.top_content_side {
  position: relative;
  width: 230px;
  margin: -22px 0 0 0;
  @include sp {
    width: 100%;
    margin-top: 0;
    margin-bottom: auto;
    padding: 20px 0 0 0;
    background-image: url("/container/common/images/bg-main-content.png");
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 80px 80px 0 0;
      border-color: $colorBrown transparent transparent transparent;
      position: absolute;
      top:0;
      left:0;
    }
  }
  &--pickup {
    display: none;
    position: absolute;
    top:20px;
    left:0;
    transform: rotate(-45deg);
    @include sp {
      display: block;
    }
  }
  &--bnr {
    position: relative;
    line-height: 0;
    display: block;
    transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    overflow: hidden;
    margin-bottom: 10px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover {
      img {
        transform: scale(0.95, 0.95);
      }
      &:after {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) inset;
      }
    }
    &.show {
      left: 0;
      opacity: 1;
    }
    @include sp {
      display: block;
      width: 230px;
      margin: 0 auto;
      padding-bottom:20px;
    }
  }
  &--bnr_img {
    transition: 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 100%;
  }
}

.top_big_btn {
  margin: 22px 0 0 0;
  position: relative;
  top: 0;
  text-align: center;
  background-color: $colorYellow;
  width: 100%;
  transition: .15s;
  color: $colorBrown;
  text-decoration: none;
  height: 66px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  &--img {
    width: 180px;
  }
  &:hover {
    background-color: darken($colorYellow, 5%);
    box-shadow: 0 0 0 #b79a21;
  }
  @include sp {
    display: none;
    width: 230px;
    margin: 0 auto 20px auto;
  }
}

.top_archive_list {
  background-color: #fff;
  border: 1px solid #ccc;
  min-height: 112px;
  &--item {
    padding: 0 20px;
    @include fz(12);
		line-height: 1.5;
    &:last-child {
      .top_archive_list--link {
        border-bottom: 0;
      }
    }
  }
  &--link {
    padding: 8px 0 12px 20px;
    position: relative;
    text-decoration: none;
    display: block;
    border-bottom: 1px dotted #ddd5cd;
    color: $colorBrown;
    &:before {
      position: absolute;
      top: calc(50% - 3px);
      left: 0;
      content: "";
      display: block;
      transform: rotate(-90deg);
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: $colorBrown transparent transparent transparent;
      transition: .15s;
    }
    &:after {
      position: absolute;
      top: 5px;
      left: -14px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% + 28px);
      height: calc(100% - 10px);
      border-radius: 5px;
      transform: scale(.9, .9);
      transition: .15s;
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
  }
  &--date {
    @include fz(12);
    margin: 0 0 5px 0;
    display: inline-block;
  }
  &--icon {
    margin: 0 0 0 5px;
    position: relative;
    top: 2px;
    display: inline-block;
  }
  &--ttl {
    @include fz(14);
  }
}

.new_release {
  position: relative;
  padding: 40px 0;
  background: #f0ebe0;
  &--zigzag {
    position: relative;
    background: #f0ebe0;
    height: 30px;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: -30px;
      z-index: 5;
      display: block;
      height: 30px;
      background-size: 30px 100%;
      background-image: linear-gradient(135deg, #f0ebe0 25%, transparent 25%), linear-gradient(225deg, #f0ebe0 25%, transparent 25%);
      background-position: 0 0;
      transform: rotate(180deg)
    }
  }
  &--inner {
    position: relative;
    width: 960px;
    margin: 0 auto;
    &:after {
      content: "";
      width: 200px;
      height: 130px;
      background-image: url("/container/common/images/charactor03.png");
      background-repeat: no-repeat;
      position: absolute;
      bottom: -35px;
      right: 0;
    }
    @include sp {
      width: 100%;
      padding-bottom:80px;
      &:after {
        right: 17%;
      }
    }
  }
  &--ttl {
    @include fz(24);
    font-weight: 600;
    margin: 0 0 40px 0;
    color: $colorBrown;
    text-align: center;
  }
  &--more_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c8bdb2;
    text-decoration: none;
    color: #fff;
    @include fz(14);
    width: 260px;
    height: 50px;
    margin: 0 auto;
    border-radius: 25px;
    transition: .15s;
    &:hover {
      background: darken(#c8bdb2, 15%);
    }
  }
  &--side {
    position: absolute;
    top:70px;
    right:0;
    width: 230px;
    @include sp {
      position: relative;
      margin: 30px auto;
      top:auto;
      right:auto;
    }
  }
  &--btn {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    @include fz(18);
    text-align: center;
    color: #fff;
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    height: 72px;
    border-radius: 5px;
    background-color: #916843;
    box-shadow: 0 2px 0 #775b4d;
    transition: .15s;
    &:before {
      @include icon2;
      @include fz(30);
      margin: 0 10px 0 -10px;
      content: $icon-change;
      display: inline-block;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      box-shadow: 0 0 0 #775b4d inset;
      transition: .15s;
    }
    &:hover {
      top: 2px;
      background-color: darken(#916843, 5%);
      box-shadow: 0 0 0 #775b4d;
      &:after {
        box-shadow: 0 2px 0 #775b4d inset;
      }
    }
    @include sp {
      
    }
  }
}

.new_release_list {
  list-style: none;
  width: 690px;
  margin: 0 0 30px 0;
  @include sp {
    width: 90%;
    margin: 0 auto 30px auto;
  }
  &--item {
    border-bottom: 2px dashed #fff;
    padding: 15px 0 10px 0;
  }
  &--link {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    &:after {
      position: absolute;
      top: -8px;
      left: -8px;
      opacity: 0;
      background-color: #c89b05;
      content: "";
      display: block;
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      border-radius: 5px;
      transition: .15s;
      transform: scale(.9, .9);
    }
    &:hover {
      &:after {
        opacity: .3;
        transform: scale(1, 1);
      }
    }
    @include sp {
      display: block;
      .release {
        display: block;
        margin: 0 0 5px 0;
      }
    }
  }
  &--description {
    width: 580px;
    @include fz(14);
    line-height: 1.5;
    @include sp {
      width: auto;
    }
  }
}